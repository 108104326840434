.search-container {
  box-shadow: rgb(0 0 0 / 2%) 0px 1px 3px 0px,
    rgb(27 31 35 / 15%) 0px 0px 0px 1px;
  border: 0;
  padding: 5px 20px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  max-width: 450px;
  width: 100%;
}

.search-icon {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-right: 8px;
  cursor: pointer;
}

.search-input {
  outline: 0;
  border: 0;
  padding: 8px 0;
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  nav {
    gap: 15px;
  }

  .search-input {
    padding: 6px 10px;
  }
}
