:root {
  --white-color: #fff;
  --color-text-link: #22863a /*#0366d6;*/;
  --text-color-green: #22863a;
  --color-label-success-text: #22863a;
  --color-label-success-border: #28a745;
  --color-bg-tertiary: #f6f8fa;
  --color-text-green: #31636e;
  --color-background-green: #bfe0d6;
  --color-auto-pink-5: #ea4aaa;
  --black: #18181b;
  --black-light: #58585f;
  --black-medium: #2c2c30;
  --gray-light: #f6f9fc;
  --gray-medium: #dce6e9;
  --background: var(--gray-light);
  --border-color: var(--gray-medium);
}

* {
  box-sizing: border-box;
}

::selection {
  background: #22863a;
  color: #fff;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.5;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 15px 0;
}

li {
  cursor: pointer;
}

li:not(.fa_user) {
  margin-right: 1.5em;
}

.nav-icons {
  display: flex;
  margin: 0;
}

.heading {
  margin: 0;
}

h1 {
  font-size: 2em;
}

hr {
  margin-top: 1em;
}

.app-top-blurb {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #222;
  color: #fff;
  padding: 7.5px 20px;
  font-size: 11px;
  position: sticky;
  top: 0;
}

.home-page-back-cta {
  margin-top: 15px;
}

.home-page-back-cta a {
  display: flex;
  align-items: center;
}

.home-page-back-cta a:hover {
  color: var(--color-text-link);
}

.nav-icons {
  list-style: none;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
}

.inner-section {
  display: flex;
  justify-content: space-between;
}

.section1-row1 {
  height: 620px;
  background: #ddd;
  flex: auto;
  margin: 20px 10px 20px 0px;
  border-radius: 10px;
}

.row2-col1,
.row2-col2,
.row3-col1,
.row3-col2 {
  height: 300px;
  background: #eee;
  flex: auto;
  border-radius: 10px;
}

.row2-col2,
.row3-col2,
.section2-row1-col2,
.section3-row1-col2 {
  margin-top: 20px;
  border-radius: 10px;
}

.row2 {
  flex: auto;
  margin: 20px 10px 15px 10px;
  height: 600px;
}

.row3 {
  flex: auto;
  margin: 20px 0px 15px 10px;
  height: 600px;
}

.section1-heading-nav,
.section2-heading-nav,
.section3-heading-nav {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}

a {
  text-decoration: none;
  color: #a5a5a5;
}

.nav-more {
  color: #222;
  cursor: pointer;
}

button {
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid green;
  background: #fff;
}

.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #000 !important;
  color: #fff;
  font-size: 1em;
}

.swal2-input {
  text-align: center;
  box-shadow: none !important;
}

footer ul {
  margin-top: 20px;
  display: flex;
}
