.home-page {
  width: 90%;
  margin: 0 auto;
  max-width: 2650px;
}

.home-title {
  width: 100%;
  display: flex;
  align-items: center;
}

.nav-more,
.nav-more a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-more a {
  color: #222;
  font-size: 13px;
  text-transform: uppercase;
}

.nav-more:hover {
  color: var(--color-auto-pink-5);
}

.submit-cta {
  color: #222;
}

.nav-more,
.nav-icons,
.submit-cta,
.heading,
.home-title {
  user-select: none;
}

.submit-cta:hover {
  color: var(--color-text-link);
}

.nav-icon:hover {
  color: var(--color-text-link);
}

.nav-icons li {
  list-style: none;
}

.label-container {
  text-align: center;
  margin: 0 auto 10px;
}

.label {
  display: inline-block;
  padding: 0 7px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 2em;
  background-color: transparent !important;
  border: 1px solid transparent;
  border-color: var(--color-label-border);
}

.label-success {
  color: var(--color-label-success-text);
  border-color: var(--color-label-success-border);
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #fff;
  padding: 10px;
  margin: 0 auto;
  margin-top: 20px;
  grid-gap: 15px;
  user-select: none;
}

.card-item {
  color: #222;
  border: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  background: #fff;
  flex: auto;
  border-radius: 10px;
}

.card-item img {
  height: 50px;
  width: 50px;
  opacity: 0.6;
}

.card-item-left {
  display: flex;
  align-items: center;
  border: 0;
  padding: 0 20px;
}

.card-item-right {
  border-left: 1px solid #ddd;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card-item-right-header {
  box-sizing: border-box;
  height: 100%;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  line-height: 1.4;
}

.card-item-right-footer {
  color: var(--color-label-success-text);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 10px;
  font-size: 12px;
  /* color: rgba(117, 117, 117, 1); */
}

@media screen and (max-width: 768px) {
  .cards-container {
    display: block;
    margin: 0 auto;
  }

  .card-item {
    margin: 10px auto;
  }
}
