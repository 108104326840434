.app-custom-install {
  background-color: rgb(250, 250, 250);
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  margin: 10px 0;
  padding: 10px 20px;
  gap: 10px;
  width: 100%;
}

.app-custom-install-info {
  display: block;
  flex: 1;
}

.app-custom-install-text {
  font-size: 13px;
  color: #000;
  margin: 0;
  margin-top: 1px;
}

.app-custom-install-img {
  width: 40px;
}

.app-custom-install-btn {
  border: 1px solid #222;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  padding: 8px 10px;
  width: 85px;
}

.app-custom-install-btn:hover {
  background-color: #222;
  color: #fff;
}

/* Media Queries */
@media (max-width: 1180px) {
  .app-custom-install {
    position: fixed;
    bottom: 20px;
    left: 10px;
    max-width: 350px;
  }
}
