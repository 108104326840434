.posts-container {
  margin: 0 auto;
  padding: 30px 0;
  width: 100%;
}

.lazy-loader {
  height: 210px;
  background-color: #eee;
  border: 0;
}

.post-item {
  display: flex;
  line-height: 1.5;
  margin: 0;
  margin-bottom: 25px;
}

.post-item-date {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier,
    monospace;
  color: #24292e;
  padding-right: 10px;
  font-size: 14px;
}

.post-item-date a {
  color: var(--color-label-success-text);
}
.post-item-content {
  display: block;
  width: 100%;
}

.selected-tag {
  background-color: #28a745 !important;
  color: #fff;
}

.selected-tag a {
  color: #fff !important;
}

.post-item-categories {
  display: flex;
  flex-wrap: wrap;
  color: #6a737d;
  margin: 0;
  margin-bottom: 16px;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier,
    monospace;
}

.post-item-categories li,
.slug {
  margin: 0;
  list-style: none;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid var(--color-label-success-border);
}

.post-item-categories li:last-child {
  margin-right: 0;
}

/* .post-item-categories li:last-child:after{
  content: none;
} */

/* .post-item-categories li:after {
  content: ",";
  margin-right: 1em;
} */

.post-item ol {
  padding-left: 0;
  margin-top: 0;
}

.post-item ul {
  padding-left: 0;
  margin-top: 0;
}

.post-item-categories a {
  color: #6a737d;
}

.post-item-title a {
  color: #24292e;
}

.post-item-title:hover a {
  text-decoration: underline;
  color: var(--color-text-link);
}

.post-item-excerpt {
  margin: 0;
  color: #586069;
  margin-bottom: 16px;
}

.author-block {
  display: inline-flex;
  align-items: center;
  color: #586069;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.author-block-pic {
  border-radius: 50%;
  height: 30px;
  overflow: hidden;
  width: 30px;
  margin-right: 10px;
}

.post-author-name {
  margin: 0;
  font-size: 14px;
}

.post-author-name:hover {
  color: var(--color-text-link);
}

.article-content {
  display: flex;
}

.article-aside {
  border: 0;
  padding-left: 25px;
  border-left: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 500px;
}

.article-aside-inner {
  background-color: rgb(250, 250, 250);
  border: 0px solid #ddd;
  padding-bottom: 10px;
  margin-top: 20px;
  width: 100%;
  border-radius: 10px;
}

.article-aside-header {
  padding: 10px 20px;
  border: 0;
  border-bottom: 1px solid #ddd;
  font-weight: 600;
}

.article-aside-content {
  padding: 10px 20px;
  font-size: 13.8px;
}

.article-aside-content p {
  padding: 0;
  margin: 0;
}

.link-external {
  display: inline-flex;
  align-items: center;
  color: var(--color-text-link);
  font-weight: 500;
}

@media (min-width: 768px) {
  .post-item-date {
    width: 30%;
    margin-right: 15px;
  }

  .post-item-title {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .posts-container {
    padding: 30px;
    padding-right: 40px;
    padding-left: 0;
  }
}

@media (max-width: 1175px) {
  .article-aside {
    display: none;
  }
}

@media (max-width: 768px) {
  .article-aside {
    display: none;
  }
  .post-item {
    display: block;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
  }
  .post-item-date {
    margin-bottom: 5px;
  }
}
